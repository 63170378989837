const ExtLinkLogo = ({ fill, width, height }) => {
  return (
    <svg
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2857 0C18.1004 0 17.1429 0.89375 17.1429 2C17.1429 3.10625 18.1004 4 19.2857 4H22.6808L11.3437 14.5875C10.5067 15.3687 10.5067 16.6375 11.3437 17.4188C12.1808 18.2 13.5402 18.2 14.3772 17.4188L25.7143 6.83125V10C25.7143 11.1062 26.6719 12 27.8571 12C29.0424 12 30 11.1062 30 10V2C30 0.89375 29.0424 0 27.8571 0H19.2857ZM5.35714 2C2.39732 2 0 4.2375 0 7V23C0 25.7625 2.39732 28 5.35714 28H22.5C25.4598 28 27.8571 25.7625 27.8571 23V18C27.8571 16.8937 26.8996 16 25.7143 16C24.529 16 23.5714 16.8937 23.5714 18V23C23.5714 23.55 23.0893 24 22.5 24H5.35714C4.76786 24 4.28571 23.55 4.28571 23V7C4.28571 6.45 4.76786 6 5.35714 6H10.7143C11.8996 6 12.8571 5.10625 12.8571 4C12.8571 2.89375 11.8996 2 10.7143 2H5.35714Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExtLinkLogo;
